// with polyfills
import '../config/table.conf'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import i18n from './locales'
import { VueAxios } from './utils/request'
import ProLayout, { PageHeaderWrapper } from '@ant-design-vue/pro-layout'
import themePluginConfig from '../config/themePluginConfig'
import _ from 'lodash'
// mock
// WARNING: `mockjs` NOT SUPPORT `IE` PLEASE DO NOT USE IN `production` ENV.
// import './mock'

import bootstrap from './core/bootstrap'
import './core/lazy_use' // use lazy load components
import './permission' // permission control
import './utils/filter' // global filter
import './global.less' // global style
import { Tree, TreeSelect, Pagination, popover, ConfigProvider, Modal, Empty } from 'ant-design-vue'
import VueVisible from './directives/v-visible'
import VuePermission from './directives/v-permission'
import VueMask from './directives/v-mask'
import VueFixed from './directives/v-fixed'
import XLSX from 'xlsx'
import RouterTab from 'vue-router-tab'
import 'vue-router-tab/dist/lib/vue-router-tab.css'

import moment from 'moment'

import fontawesome from '@fortawesome/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import solid from '@fortawesome/fontawesome-free-solid'
import regular from '@fortawesome/fontawesome-free-regular'
import brands from '@fortawesome/fontawesome-free-brands'

fontawesome.library.add(solid)
fontawesome.library.add(regular)
fontawesome.library.add(brands)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(VueAxios)
Vue.use(TreeSelect)
Vue.use(Tree)
Vue.use(Pagination)
Vue.use(popover)
Vue.use(ConfigProvider)
Vue.use(Modal)
Vue.use(VueVisible)
Vue.use(VuePermission)
Vue.use(VueMask)
Vue.use(VueFixed)
Vue.use(RouterTab)
Vue.use(Empty)

Vue.prototype.moment = moment
window._moment = moment
Vue.prototype.$confirm = Modal.confirm
Vue.prototype._ = _
window._ = _
Vue.prototype.XLSX = XLSX

// use pro-layout components
Vue.component('pro-layout', ProLayout)
Vue.component('page-container', PageHeaderWrapper)
Vue.component('page-header-wrapper', PageHeaderWrapper)

// 空盒组件
import EmptyBox from '@/components/EmptyBox'
Vue.component('EmptyBox', EmptyBox)

window.umi_plugin_ant_themeVar = themePluginConfig.theme

new Vue({
  router,
  store,
  i18n,
  // init localstorage, vuex
  created: bootstrap,
  render: h => h(App)
}).$mount('#app')
