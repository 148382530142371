
// bus_gl_depreciation_type
const constDict = {
  state: {
     // 折旧方式
    depreciation: 'bus_gl_depreciation_type',
    // 财务账户类型
    userType: 'bus_gl_fa_type',
    // 财务账户方向
    direction: 'bus_gl_fa_direction',
    // 启用状态
    status: 'sys_enabled_status',
    // 审核状态
    examineStatus: 'sys_approval_status',
    // 收支类别
    busGlBalanceType: 'bus_gl_balance_type',
    // 编号规则类型
    numberingType: 'bus_numbering_type'
  }
}
export default constDict
