import store from "../store"

const vPermission = {
  install (Vue) {
    Vue.directive('permission', (el, binding) => {
      const value = binding.value
      const buttons = store.getters.buttons
      // v-permission="['aaa','bbb']"
      // if (buttons.includes(value) || buttons.includes('@*:*:*@')) {
      let hasRole
      if (value && value instanceof Array && value.length > 0) {
        const roleFlag = value
        hasRole = buttons.some(role => {
          return '*:*:*' === role || roleFlag.includes(role)
          // return roleFlag.includes(role)
        })
      }
      if (hasRole) {
        el.style.visibility = 'visible'
      } else {
        el.style.display = 'none'
      }
    })
  }
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(vPermission)
}

export default vPermission
