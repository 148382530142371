import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import user from './modules/user'
import constDict from './modules/const-dict'

import permission from './modules/permission'

import getters from './getters'

import  tagsView from './modules/tagsView'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    user,
    permission,
    constDict,
    tagsView
  },
  state: {

  },
  mutations: {

  },
  actions: {

  },
  getters
})
