<template>
  <pro-layout
    :menus="menus"
    :collapsed="collapsed"
    :mediaQuery="query"
    :isMobile="isMobile"
    :handleMediaQuery="handleMediaQuery"
    :handleCollapse="handleCollapse"
    :i18nRender="i18nRender"
    v-bind="settings"
    :siderWidth="250"
    @click="handleClickMenu"
  >
    <!--    @titleClick="handleClickMenu"-->
    <!-- 1.0.0+ 版本 pro-layout 提供 API，
          我们推荐使用这种方式进行 LOGO 和 title 自定义
    -->
    <template v-slot:menuHeaderRender>
      <div>
        <img src="/suju_logo_white.png" style="width: 32px;height: 32px"/>
        <h1>{{ title }}</h1>
      </div>
    </template>
    <!-- 1.0.0+ 版本 pro-layout 提供 API,
          增加 Header 左侧内容区自定义
    -->
<!--        <template v-slot:headerContentRender>-->
<!--          <div class='clearfix'>-->
<!--            <div style='float: left'>-->
<!--              <a-tooltip title="刷新页面">-->
<!--                <a-icon type="reload" style="font-size: 18px;cursor: pointer;" @click="reload" />-->
<!--              </a-tooltip>-->
<!--            </div>-->
<!--            <multi-tab  style='float: left' v-if="multiTab"></multi-tab>-->
<!--          </div>-->
<!--        </template>-->

<!--        <setting-drawer  :settings="settings" @change="handleSettingChange">-->
<!--          <div style="margin: 12px 0;">-->
<!--            这是SettingDrawer自定义页脚内容。-->
<!--          </div>-->
<!--        </setting-drawer>-->

        <template v-slot:rightContentRender>
          <right-content :top-menu="settings.layout === 'topmenu'" :is-mobile="isMobile" :theme="settings.theme" />
        </template>

    <!-- custom footer / 自定义Footer -->
    <template v-slot:footerRender>
      <!--      <global-footer />-->
    </template>
    <router-tab keep-alive restore default-page='/home' :max-alive='20'/>
    <!--    <tags-view/>-->

    <!--      <router-view v-if="isReload"  />-->
  </pro-layout>
</template>

<script>

import MultiTab from '@/components/MultiTab'
import config from '@/config/defaultSettings'//原来就存在了
// 引入组件和样式

import { SettingDrawer, updateTheme } from '@ant-design-vue/pro-layout'
import { i18nRender } from '@/locales'
import { mapState } from 'vuex'
import { CONTENT_WIDTH_TYPE, SIDEBAR_TYPE, TOGGLE_MOBILE_TYPE } from '@/store/mutation-types'
import defaultSettings from '@/config/defaultSettings'
import RightContent from '@/components/GlobalHeader/RightContent'
import GlobalFooter from '@/components/GlobalFooter'
import Ads from '@/components/Other/CarbonAds'

import TagsView  from './TagsView'

export default {
  name: 'BasicLayout',
  provide () {
    return {
      // 重载本页
      reload: this.reload
    }
  },
  components: {
    SettingDrawer,
    RightContent,
    GlobalFooter,
    Ads,
    MultiTab,
    TagsView
  },
  data () {
    return {
      // preview.pro.antdv.com only use.
      isProPreviewSite: process.env.VUE_APP_PREVIEW === 'true' && process.env.NODE_ENV !== 'development',
      // end
      routeTab: null,// routeTab 存放在 data 中以支持响应
      // base
      menus: [],
      // 侧栏收起状态
      collapsed: false,
      title: defaultSettings.title,
      settings: {
        // 布局类型
        layout: defaultSettings.layout, // 'sidemenu', 'topmenu'
        // CONTENT_WIDTH_TYPE
        contentWidth: defaultSettings.layout === 'sidemenu' ? CONTENT_WIDTH_TYPE.Fluid : defaultSettings.contentWidth,
        // 主题 'dark' | 'light'
        theme: defaultSettings.navTheme,
        // 主色调
        primaryColor: defaultSettings.primaryColor,
        fixedHeader: defaultSettings.fixedHeader,
        fixSiderbar: defaultSettings.fixSiderbar,
        colorWeak: defaultSettings.colorWeak,

        hideHintAlert: false,
        hideCopyButton: false
      },
      // 媒体查询
      query: {},

      // 是否手机模式
      isMobile: false,
      isReload: true,
      multiTab: config.multiTab,
      fixedHeader: config.fixedHeader
    }
  },
  computed: {
    ...mapState({
      // 动态菜单
      mainMenu: state => state.permission.addRouters
    })
  },
  created () {

    const routes = this.mainMenu.find(item => item.path === '/')
    this.menus = (routes && routes.children) || []
    // 处理侧栏收起状态
    this.$watch('collapsed', () => {
      this.$store.commit(SIDEBAR_TYPE, this.collapsed)
    })
    this.$watch('isMobile', () => {
      this.$store.commit(TOGGLE_MOBILE_TYPE, this.isMobile)
    })

  },


  mounted () {
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('Edge') > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed
        setTimeout(() => {
          this.collapsed = !this.collapsed
        }, 16)
      })
    }

    // first update color
    // TIPS: THEME COLOR HANDLER!! PLEASE CHECK THAT!!
    if (process.env.NODE_ENV !== 'production' || process.env.VUE_APP_PREVIEW === 'true') {
      updateTheme(this.settings.primaryColor)
    }
  },
  methods: {
    i18nRender,
    handleMediaQuery (val) {
      this.query = val
      if (this.isMobile && !val['screen-xs']) {
        this.isMobile = false
        return
      }
      if (!this.isMobile && val['screen-xs']) {
        this.isMobile = true
        this.collapsed = false
        this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
        // this.settings.fixSiderbar = false
      }
    },
    handleCollapse (val) {
      this.collapsed = val
    },
    handleSettingChange ({ type, value }) {
      type && (this.settings[type] = value)
      switch (type) {
        case 'contentWidth':
          this.settings[type] = value
          break
        case 'layout':
          if (value === 'sidemenu') {
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
          } else {
            this.settings.fixSiderbar = false
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fixed
          }
          break
      }
    },
    handleClickMenu (value) {
    },
    // 重载本页
    reload () {
      // this.$tabs.refresh()
      this.isReload = false
      this.$nextTick(() => {
        this.isReload = true
      })
    }
  }
}
</script>

<style lang="less">
@import "./BasicLayout.less";
.main-layout{
  user-select: none;
}
.ant-pro-basicLayout-content,.ant-card-body{
  margin: 1px 2px 0 2px;
}
.ant-pro-multi-tab{
  margin: 10px 0 0 10px;
}
</style>
