import storage from 'store'
import { login, getInfo, logout } from '@/api/login'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { welcome } from '@/utils/util'
import { setToken,setExpiresIn,removeExpiresIn,removeToken } from '@/utils/auth'

const user = {
  state: {
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    permission: [],
    info: {},
    userInfo: {}
    // test: 'testtesttesttesttesttesttesttesttest'
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_PERMISSION: (state, permission) => {
      state.permission = permission
    },
    // SET_INFO: (state, info) => {
    //   console.log(info, 'info')
    //   state.info = info
    // },
    SET_USER_INFO: (state, info) => {
      state.userInfo = info
    }
  },

  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          const { code, data } = response
          if (code === 200) {
            // eslint-disable-next-line camelcase
            const { access_token, expires_in } = data
            // eslint-disable-next-line camelcase
            // storage.set(ACCESS_TOKEN, access_token, expires_in - new Date().getTime() / 1000 - 300)
            setExpiresIn(expires_in)
            setToken(access_token)
            commit('SET_TOKEN', access_token)
            resolve(200)
          } else {
            reject(code)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(response => {
          const { data: result, code } = response
          if (code === 200) {
            if (result.permissions && result.permissions.length > 0) {
              commit('SET_PERMISSION', result.permissions)
              // commit('SET_INFO', result)
              commit('SET_USER_INFO', result.userInfo)
            } else {
              reject(new Error('权限不能为空 !'))
            }

            commit('SET_NAME', { name: result.userInfo.realName, welcome: welcome() })
            commit('SET_AVATAR', '')

            resolve(response)
          } else {
            reject(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_PERMISSION', [])
          // storage.remove(ACCESS_TOKEN)
          removeExpiresIn()
          removeToken()
          resolve()
        }).catch(() => {
          resolve()
        }).finally(() => {
          window.location.href = '/'
        })
      })
    }

  }
}

export default user
