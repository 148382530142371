// eslint-disable-next-line
import { UserLayout, BasicLayout } from '@/layouts'
// import { bxAnaalyse } from '@/core/icons'
// RouterTab 内置路由
import { RouterTabRoutes } from 'vue-router-tab'

export const RouteView = {
  name: 'RouteView',
  render: h => h('router-view')
}

export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: 'menu.home' },
    redirect: '/home',
    // 引入 RouterTab 内置路由以支持 Iframe 页签
    ...RouterTabRoutes,
    children: [
      {
        path: '/home',
        name: '首页',
        component: () => import('@/views/home'),
        meta: {
          title: '主页',
          keepAlive: true,
          key: 'path', // 路由打开页签规则，可选,
          icon: 'home',
          closable: false, // 页签是否允许关闭，默认 `true`
          permission: ['dashboard'] }
      }
    ]
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
  },

  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }
]
