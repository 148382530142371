<template>
  <global-footer class="footer custom-render" :links="[]">
    <template v-slot:copyright>
      <a href="" target="_blank">速聚科技</a>
    </template>
  </global-footer>
</template>

<script>
import { GlobalFooter } from '@ant-design-vue/pro-layout'

export default {
  name: 'ProGlobalFooter',
  components: {
    GlobalFooter
  }
}
</script>
<style lang="less" scoped>
.title-span{
  user-select: none;
}
</style>
