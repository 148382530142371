
const getters = {
  isMobile: state => state.app.isMobile,
  lang: state => state.app.lang,
  theme: state => state.app.theme,
  color: state => state.app.color,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  nickname: state => state.user.name,
  welcome: state => state.user.welcome,
  permission: state => state.user.permission,
  buttons: state => state.permission.buttons,
  userInfo: state => state.user.userInfo,
  // users: state => state,
  addRouters: state => state.permission.addRouters,
  multiTab: state => state.app.multiTab,
  constDict: state => state,
  periodName:state => state.globalInfo.periodName,
  globalInfo: state => state
}

export default getters
