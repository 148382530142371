import Vue from 'vue'
import XEUtils from 'xe-utils'
// import VXETablePluginShortcutKey from 'vxe-table-plugin-shortcut-key'
import {
  VXETable,
  Header,
  Column,
  Table,
  Colgroup,
  Edit,
  Input,
  Export,
  Keyboard,
  Pager,
  Select
} from 'vxe-table'
import VXETablePluginAntd from 'vxe-table-plugin-antd'
import zhCN from 'vxe-table/lib/locale/lang/zh-CN'
// import VXETablePluginShortcutKey from 'vxe-table-plugin-shortcut-key'
import 'vxe-table-plugin-antd/dist/style.css'
import 'vxe-table/lib/style.css'
// 按需加载的方式默认是不带国际化的，自定义国际化需要自行解析占位符 '{0}'，例如：
VXETable.setup({
  i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args)
})

// 表格功能
Vue.use(Header)
  // 可选组件
  .use(Column)
  // 安装表格
  .use(Edit)
  .use(Input)
  .use(Export)
  .use(Keyboard)
  .use(Table)
  .use(Colgroup)
  .use(Pager)
  .use(Select)

VXETable.use(VXETablePluginAntd)
// VXETable.use(VXETablePluginShortcutKey)

// "vxe-table-plugin-shortcut-key": "^1.5.1",
// VXETable.use(VXETablePluginShortcutKey, {
//   setting: {
//     'table.edit.tab.rightMove': 'Tab',
//     'table.edit.actived': 'Tab' // 将 Tab 键的按键值修改为回车键
//   }
//   })
