export default {
  install (Vue) {
    Vue.directive('pin', (el, binding) => {
         // 只调用一次，指令第一次绑定到元素时调用。在这里可以进行一次性的初始化设置。
        el.style.position = 'fixed'
        let s = 'top'
        if (['top', 'bottom', 'left', 'right'].includes(binding.arg)) {
           s = binding.arg
        }

        el.style[s] = binding.value + 'px'

    })
  }
}

