const vVisible = {
  install (Vue) {
    Vue.directive('visible', (el, binding) => {
      const value = binding.value

      if (value) {
        el.style.visibility = 'visible'
      } else {
        el.style.visibility = 'hidden'
      }
    })
  }
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(vVisible)
}

export default vVisible
