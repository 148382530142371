import request from '@/utils/request'

const userApi = {
  Login: '/login/login',
  Logout: '/login/logout',

  UserInfo: '/login/getInfo',
  UserMenu: '/user/nav',

  GetCode: '/login/getCode'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login (parameter) {
  return request({
    url: userApi.Login,
    method: 'post',
    data: parameter
  })
}

export function getSmsCaptcha (parameter) {
  return request({
    url: userApi.SendSms,
    method: 'post',
    data: parameter
  })
}

export function getInfo () {
  return request({
    url: userApi.UserInfo,
    method: 'get'
  })
}

export function getCurrentUserNav () {
  return request({
    url: userApi.UserMenu,
    method: 'get'
  })
}

export function logout () {
  return request({
    url: userApi.Logout,
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg () {
  return request({
    url: userApi.GetCode,
    method: 'get'
  })
}

// 获取账套列表
export function selectAccountSet () {
  return request({
    url: '/login/selectAccountSet',
    method: 'get'
  })
}

// token续期
export function refresh () {
  return request({
    url: '/login/refresh',
    method: 'post'
  })
}
