import { RouteView, asyncRouterMap, constantRouterMap } from '@/config/router.config'
import { getToken } from '@/utils/auth'
// RouterTab 内置路由
import { RouterTabRoutes } from 'vue-router-tab'

function transMenu (permissions, patient = null) {
  let res = []
  _.each(permissions, item => {
    // 树和标签直接跳过
    if ([4, 5].includes(item.menuType) || !item.routerPath) {
      return
    }
    const path = patient !== null ? patient.path + (item.routerPath[0] === '/'?'':'/') + item.routerPath : item.routerPath
    const resItem = {
      path: path,
      // 路由名称，建议唯一
      name: item.key,
      component: () => import('@/views/' + item.component),
      hideChildrenInMenu: item.showStatus === 0,
      hidden: item.showStatus === 0,
      meta: {
        title: item.name,
        icon: item.menuIcon
        // keepAlive: true
      }
    }
    // 重定向
    if (item.isFrame === 1) {
      // if (!_.startsWith(path, 'http')) {
      //   resItem.redirect = path
      // } else {
      resItem.component = () => import('@/components/RouteIframe')
      resItem.path = '/iframe/' + item.routerPath
      let slug = item.routerPath.indexOf("?")>=0?"&":"?"
      resItem.meta = Object.assign(resItem.meta, {
        'src': item.routerPath + slug + "token=" + getToken()
      })
      // }
    }

    if (item.children && item.children.length > 0) {
      resItem.component = RouteView
      // res  = [...res , ... transMenu(item.children, resItem)]
      resItem.children = transMenu(item.children, resItem)
      // resItem.children = transMenu([...item.children,...RouterTabRoutes], resItem)
    }

    res.push(resItem)
  })
  return res
}

function genAsyncRouter (permissions, asyncRouterMap) {
  // 合并后端路由数据到前端
  asyncRouterMap[0]['children'] = [...asyncRouterMap[0]['children'], ...transMenu(permissions)]
  return asyncRouterMap
}

/**
 * 获取按钮权限
 * @param permissions
 * @returns {[]}
 */
function getAsyncButton(purview) {
  let res  = []
  let getButton = function(purview){
    _.each(purview, item => {
      // if (item.children && item.children.length > 0) {
      //   getButton(item)
      // }else {
      //   if (item.menuType === 3){
      //     res.push(`@${item['perms']}@`)
      res.push(item)
      // }
      // }
    })
  }
  getButton(purview)
  return res
}

const permission = {
  state: {
    routers: constantRouterMap,
    addRouters: [],
    buttons:[]
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
      state.routers = constantRouterMap.concat(routers)
    },
    SET_BUTTONS: (state, buttons) => {
      state.buttons = buttons
    },
  },
  actions: {
    GenerateRoutes ({ commit }, data) {
      return new Promise(resolve => {
        // 暂时返回全部数据
        let { permissions,purview } = data

        const accessedRouters = genAsyncRouter(permissions, asyncRouterMap)
        const buttons = getAsyncButton(purview)
        commit('SET_BUTTONS',buttons)
        commit('SET_ROUTERS', accessedRouters)
        resolve()
      })
    },

    HasPermission({state},data) {
      return state.buttons.includes(data)
    }

  }
}

export default permission
