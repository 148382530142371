// import storage from 'store'
import axios from 'axios'
import storage from 'store'
import { ACCESS_TOKEN, ACCESS_TOKEN_EXPIRES } from '@/store/mutation-types'
import store from '../store'

export function getToken () {
  return storage.get(ACCESS_TOKEN)
}

export function setToken (token) {
  return storage.set(ACCESS_TOKEN, token)
}

export function removeToken () {
  return storage.remove(ACCESS_TOKEN)
}

export function removeExpiresIn () {
  return storage.remove(ACCESS_TOKEN_EXPIRES) // 清空续期时间
}

// 设置自动续期时间
export function setExpiresIn (outTime) {
  let now = (new Date().getTime() / 1000).toFixed(0)
  // 过期时间例300后秒过期的半
  let outTimeNew = ((outTime - now)/2).toFixed(0)
  // 从 localstorage 获取 token
  storage.set(ACCESS_TOKEN_EXPIRES, parseInt(now) + parseInt(outTimeNew))
  // return Cookies.set('Admin-Token-Expires', now)
}

export function getExpiresIn() {
  return storage.get(ACCESS_TOKEN_EXPIRES)
}

// 节流
let timeout = null
function throttle (func, wait) {
  const time = () => {
    const args = arguments
    if (!timeout) {
      timeout = setTimeout(() => {
        timeout = null
        func.apply(this, args)
      }, wait)
    }
  }
  time()
}

// 接口白名单
const interfaceWhitelist = [
  '/login/getCode',
  '/login/selectAccountSet',
  '/login/login',
  '/login/loginOut' // 退出登录
]

/**
 * token自动续期
 * @param interfaceConfig url信息
 */
export function autoUpdateToken (interfaceConfig) {
  const urlAll = interfaceConfig.url
  // url
  const url = urlAll.replace(interfaceConfig.baseURL, '')

  // 判断接口是否在白名单中
  if (interfaceWhitelist.includes(url)){
    return
  }

  // 现在
  const now = (new Date().getTime() / 1000).toFixed(0)
  // 之前
  const expires = getExpiresIn() || 0
  // // const expires = null
  // // 相差 后端5分钟过期
  // const differ = expires - now
  // 600秒续签一次(600秒 == 10分钟)
  // if (now >= 150) {
  // 过期时间expires， 当前时间now
  if (now >= expires) {
    // token续签
    const refreshToken = () => {
      const token = getToken()
      axios.post(process.env.VUE_APP_API_BASE_URL + '/login/refresh', {}, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(res => {
        const { code, data: { access_token,expires_in } } = res.data
        if (code === 200) {
          store.commit("SET_TOKEN",access_token)
          setExpiresIn(expires_in)
          setToken(access_token)
        }
      })
    }
    // 续签多次请求时，需要节流
    throttle(refreshToken, 2000)
  }
}
