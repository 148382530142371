<template>
  <div>
    <a-dropdown v-if="currentUser && currentUser.name" placement="bottomRight">
    <span class="ant-pro-account-avatar">
      <a-avatar size="small" src="/default_avatar.jpg" class="antd-pro-global-header-index-avatar" />
      <span>{{ currentUser.name }}</span>
    </span>
      <template v-slot:overlay>
        <a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
          <!--        <a-menu-item v-if="menu" key="center" @click="handleToCenter">-->
          <!--          <a-icon type="user" />-->
          <!--          {{ $t('menu.account.center') }}-->
          <!--        </a-menu-item>-->
          <!--        <a-menu-item v-if="menu" key="settings" @click="handleToSettings">-->
          <!--          <a-icon type="setting" />-->
          <!--          {{ $t('menu.account.settings') }}-->
          <!--        </a-menu-item>-->
          <a-menu-item v-if="menu" @click="handleToEp">
            <a-icon type="setting" />修改密码
          </a-menu-item>
          <a-menu-divider v-if="menu" />
          <a-menu-item key="logout" @click="handleLogout">
            <a-icon type="logout" />
            <span>退出登录</span>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
    <span v-else>
      <a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }" />
    </span>
    <a-modal
      v-model:visible="epModel.visible"
      title="修改密码"
      :destroyOnClose="true"
      :confirm-loading="epModel.loading"
      @ok="handleEditPassword"
    >
      <a-form :form="epModel.form">
        <a-form-item
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 20, offset: 1 }"
          label="新密码"
        >
          <a-input
            v-decorator="[
          'newPassword',
          { rules: [{ required: true, message: '密码必须为8-16位字母、数字和特殊字符组合', pattern: /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^\da-zA-Z\s]).{8,16}$/ }] },
        ]"
            placeholder="请输入新密码"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import { removeToken, removeExpiresIn } from '@/utils/auth'
import { restPassword } from '@/api/user-rights/usermanage'

export default {
  name: 'AvatarDropdown',
  props: {
    currentUser: {
      type: Object,
      default: () => null
    },
    menu: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      epModel: {
        visible: false,
        loading: false,
        password: '',
        form: this.$form.createForm(this)
      }
    }
  },
  methods: {
    handleToCenter () {
      this.$router.push({ path: '/account/center' })
    },
    handleToSettings () {
      this.$router.push({ path: '/account/settings' })
    },
    handleToEp() {
      this.epModel.visible = true
    },
    handleEditPassword() {
      this.epModel.form.validateFields(err => {
        if (!err) {
          this.epModel.loading = true
          const data = Object.assign(this.epModel.form.getFieldsValue(), {userId: this.$store.getters.userInfo.userId})
          restPassword(data).then(res => {
            if (res.code === 200) {
              Modal.success({
                title: '修改成功',
                content: res.msg,
                okText: '确定',
                onOk: ()=> {
                  return this.$store.dispatch('Logout').then(() => {
                    removeToken()
                    removeExpiresIn()
                    this.$router.push({ name: 'login' })
                  })
                }
              })
            }
          }).finally(()=> {
            this.epModel.loading = false
          })
        }
      });
    },
    handleLogout (e) {
      Modal.confirm({
        title: '提示',
        content: '您确定退出登录吗？',
        cancelText: '取消',
        okText: '确定',
        onOk: () => {
          return this.$store.dispatch('Logout').then(() => {
            removeToken()
            removeExpiresIn()
            this.$router.push({ name: 'login' })
          })
        },
        onCancel () {}
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-pro-drop-down {
  /deep/ .action {
    //margin-right: 8px;
  }
  /deep/ .ant-dropdown-menu-item {
    min-width: 100px;
  }
}
</style>
