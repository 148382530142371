import axios from 'axios'
import store from '@/store'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
import { Modal } from 'ant-design-vue'
import { getToken, removeToken, autoUpdateToken } from './auth'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 6000, // 请求超时时间
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    const token = getToken()
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  // return Promise.reject(error)
  // 异常拦截处理器統一返回999
  return Promise.resolve({
    code: 999
  })
}

// 后端状态处理器
const internalState = (response) => {

  if (response.data.code === 200) autoUpdateToken(response.config)
  if (response.data.code !== 200) {

    if (response.headers['content-type'] === 'application/vnd.ms-excel'||response.config.url === '/file/getSource') {
      // streamFileDownload(response)
    } else if (response.data.code !== 200) {
      switch (response.data.code) {
        case 1001:

          // Modal.confirm({
          //   title: '登录过期，请重新登录',
          //   // content: '登录过期，请重新登录',
          //   okText: '重新登录',
          //   // cancelText: '取消',
          //   onOk () {
          //     location.reload() // 为了重新实例化vue-router对象 避免bug
          //     removeToken() // 清除tonken
          //   }
          //   // onCancel () {
          //   // }
          // })

          Modal.warning({
            title: '登录过期，请重新登录',
            okText: '重新登录',
            onOk () {
              location.reload() // 为了重新实例化vue-router对象 避免bug
              removeToken() // 清除tonken
            }
          })

          break
        default:
          let msg = ''
          if (response.data.data && !('code' in response.data.data)) {
            // for (const item in response.data.data) { msg += item + ':' + response.data.data[item] }
            for (const item in response.data.data) { msg += response.data.data[item] }
          } else if (response.data.data && ('code' in response.data.data)) {
              msg = response.data.data.code
          }

          notification.error({
            // message: '错误提示',
            // description: response.data.msg + '\n' + msg
            message: response.data.msg,
            description: msg,
            duration: 3
          })
      }
    }
  }
    // 返回服务端返回的数据
    return response.data
}

// request interceptor
  request.interceptors.request.use(config => {
    const token = getToken()
    // 如果 token 存在
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  }, errorHandler)

// response interceptor
  request.interceptors.response.use((response) => {
    return internalState(response)
  }, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
