import { axios } from '@/utils/request'

/**
 * 分页查询用户信息表
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function getAll (parameter) {
    return axios({
        url: '/user/getAll',
        method: 'get',
        params: parameter
    })
}

/**
 * 增加用户信息表
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function add (parameter) {
    return axios({
        url: '/user/add',
        method: 'post',
        data: parameter
    })
}

/**
 * 编辑用户信息表
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function edit (parameter) {
    return axios({
        url: '/user/edit',
        method: 'post',
        data: parameter
    })
}

/**
 * 查看详情用户信息表
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function detail (parameter) {
    return axios({
        url: '/acUser/detail',
        method: 'get',
        params: parameter
    })
}

/**
 * 删除用户信息表
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function del (parameter) {
    return axios({
        url: '/user/del',
        method: 'post',
        params: parameter
    })
}
/**
 * 组织树
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function deptTree (parameter) {
  return axios({
    url: '/dept/getDeptTree',
    method: 'get',
    params: parameter
  })
}
/**
 * 获取用户角色授权
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function getRoleByUserId (parameter) {
  return axios({
    url: '/role/getRoleByUserId',
    method: 'get',
    params: parameter
  })
}
/**
 * 用户角色授权
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function roleAccredit (parameter) {
  return axios({
    url: '/user/roleAccredit',
    method: 'post',
    data: parameter
  })
}
/**
 * 获取用户数据权限
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function getDeptByUserId (parameter) {
  return axios({
    url: '/dept/getDeptByUserId',
    method: 'get',
    params: parameter
  })
}
/**
 * 用户数据授权
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function dataAccredit (parameter) {
  return axios({
    url: '/user/dataAccredit',
    method: 'post',
    data: parameter
  })
}
/**
 * 重置密码
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function restPassword (parameter) {
  return axios({
    url: '/user/restPassword',
    method: 'post',
    data: parameter
  })
}
