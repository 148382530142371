const vMask = {
  install (Vue) {
    Vue.directive('mask', (el, binding) => {

      const value = binding.value
      if (value){
        let mask = document.createElement("div")
        mask.style.position = "absolute"
        mask.style.top = "0"
        mask.style.left = "0"
        mask.style.background = 'rgba(255, 255, 255, 0.3)'
        mask.style.width = el.offsetWidth + "px"
        mask.style.height = el.offsetHeight + "px"
        el.style.position = "relative"

        el.appendChild(mask)
      }
    })
  }
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(vMask)
}

export default vMask
