/* eslint-disable */
export const printANSI = () => {
  // console.clear()
  // console.log('[antd pro] created()')
  // ASCII - ANSI Shadow
  let text = `
   ,-,--.                      ,--.-,
 ,-.'-  _\\  .--.-. .-.-.      |==' -|  .--.-. .-.-.
/==/_ ,_.' /==/ -|/=/  |      |==|- | /==/ -|/=/  |
\\==\\  \\    |==| ,||=| -|    __|==|, | |==| ,||=| -|
 \\==\\ -\\   |==|- | =/  | ,--.-'\\=|- | |==|- | =/  |
 _\\==\\ ,\\  |==|,  \\/ - | |==|- |=/ ,| |==|,  \\/ - |
/==/\\/ _ | |==|-   ,   / |==|. /=| -| |==|-   ,   /
\\==\\ - , / /==/ , _  .'  \\==\\, \`-' /  /==/ , _  .'
 \`--\`---'  \`--\`..---'     \`--\`----'   \`--\`..---'

\t\t\t\t\tPublished ${APP_VERSION}-${GIT_HASH} @ 速聚科技有限公司
\t\t\t\t\tBuild date: ${BUILD_DATE}`
  process.env.NODE_ENV === 'production' && console.log(`%c${text}`, 'color: #fc4d50')
}
